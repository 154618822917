@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  font-family: "Comfortaa", sans-serif;
  padding-top:17vh;
  overflow-x:hidden ;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hero {
  background-image: url('https://source.unsplash.com/1600x900/?garden');
  background-size: cover;
  background-position: center;
}

.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.popup-button {
  padding: 10px 15px;
  background-color: #10B981;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
}


.linkSocialBloc {
  width: 50px;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 20px;
  left: 10px;
  z-index: 10;
}

.icoLink {
  transition: transform 0.3s ease;
}

.linkIco {
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkIco:hover .icoLink {
  transform: scale(1.1);
}

button{
  transition: transform 0.3s ease !important;
}

button:hover {
  transform: scale(1.05) !important;
}

a{
  transition: transform 0.3s ease !important;
  font-weight: 400;
}

a:hover {
  color: #11B622!important;
}

.navbar {
  display: flex;
  justify-content: center;
  width: 100vw;
  align-items: center;
  height: 12vh;
  margin-bottom: 5vw;
  font-family: "Mulish", sans-serif !important;
  position: fixed;
  top: 0;
  transition: top 0.5s;
  z-index: 1000;
  background-color: white;
  color: white;
}

.navbar--hide {
  top: -300px;
}

.navbar--show {
  top: 0;
}

.navbar-logo {
height: 100%;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgNavLogo{
  height: 100%;
  width: auto;
}

.navbar-links {
  display: flex;
  list-style: none;
  width: 75%;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 10px;
  height:100%;
}

.linkNav{
  width: 100%;
  display: flex;
  justify-content: center;
  gap:10%;
  align-items: center;
}

.fakeDivCenterNav{
  width: 12.5%;
}

.navbar-links a {
  text-decoration: none;
  color: #000;
}

.navbar-icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 12.5%;
  padding-left: 0.5%;
  height: 100%;
}

.blocIcoSocial{
  height: 40%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.textNavTel{
  font-size: 0.8rem;
}

.blocTelNav{
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap:10px;
}



.navbar-icons a {
  color: #000;
  text-decoration: none;
}

.navbar-icons i {
  font-size: 24px;
}

.pageContainer{
  padding: 0 12.5vw;
  width: 100vw;
}

.pageContainerContact{
  padding: 0 12.5vw;
  width: 100vw;
}

.pageContainer2{
  padding: 0 12.5vw;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}



.blocPresLanding{
  display: flex;
  flex-direction: column;
  gap:4vh;
}

.blocBienvenue{
  display: flex;
  height:40vh;
}

.imgLanding1{
  width: 45%;
  height: auto;
  border-radius: 5px;
  box-shadow: 8px 7px 4px 0px rgba(0, 0, 0, 0.25);
}

.imgLanding1Queri{
  display: none;
}

.blocContentBienvenue{
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 5% 1%;
  text-align: center;
}

.txtContentLanding{
  text-align: center;
}

.contact-button {
  border: 1px solid #00FF00;
  color: #26CC37;
  padding: 8px 15px;
  border-radius: 25px;
  transition: background-color 0.3s, color 0.3s;
}
.contact-button:hover {
  background-color: #11B622;
  border: 1px solid #11B622;
  color: #FFFFFF;
}

.textGreen{
  color:#11B622;
}

.linkMap{
  display: flex;
  gap:1vw;
  align-items: center;
}

.blocQuiSomNous{
  display: flex;
  height: 70vh;
  margin-top: 10vw;

}

.blocContentMiddle{
  text-align: center;
  display: flex;
  height: 40vh;
  margin-top: 10vw;
  justify-content: space-between;
}

.img1Qui{
  width: 100%;
  height: 100%;
  box-shadow: 8px 7px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.img1QuiBis{
  width: 35%;
  height: 100%;
  box-shadow: 8px 7px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.img1QuiBisQuery{
  width: 80%;
  height: 100%;
  box-shadow: 8px 7px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: none;
}

.img2Qui{
  width: 70%;
  box-shadow: 8px 7px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.blocContentQui{
  width: 70%;
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  height: 100%;
  gap: 5vw;

}

.blocContentServ{
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  height: 100%;
  gap: 5vw;
  padding-right: 5%;
}

.contentServContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:2vw;
}

.blocTextQui{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1vw;
  text-align: center;
}

.blocContentMidText{
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:2vw;
}

.img1Draw{
  width: 20%;
}

.imgLonguePage{
  margin-top:10vw ;
  width: 100vw;
}


.blocFooter{
  padding:2vh 0;
  background-color: #151515;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap:1.5vw;
}

.logoFooter{
  width: 5%;
}

.blocDirectionFooter{
  width: 60%;
  display: flex;
  justify-content: space-between;
}


.linkCgu{
  color:#6f6f6f;
  cursor: pointer;
}

.linkCgu:hover{
  text-decoration: underline;
  color: white!important;
  transform: scale(1)!important;
}

.textRightFooter{
  color:#6f6f6f;
}

.mentionCgu{
  display: flex;
  justify-content: center;
}

.linkFooter{
  color:white;
  cursor: pointer;
}

.linkFooter:hover{
  transform: scale(1)!important;
}


.listeReduc{
  list-style-image: url("assets/icons/flecheListe.svg");
}

.blocReduc{
  display: flex;
  flex-direction: column;
  margin-top: 6vw;

  align-items: center;
  gap:1vw;
  justify-content: space-between;
}

.imgSigleServe{
  width: 20%;
}

.blocListeReduc{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap:2%;
}

.blocImageReduc{
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 10%;
}

.icoDrawReduc{
  width: 35%;
}

.blocListeReducContent{
  width: 50%;
}

.icoCheque{
  width: 20%;
}

.blocFormContact{
  display: flex;
  height:85vh;
  justify-content: space-between;
  margin-bottom: 10vw;
}

.blocContactContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10%;
  width: 50%;
  margin-right:5%;
}

.blocSocialContact{
  display: flex;
  gap:1vw;
}

.blocContentSocial{
  display: flex;
  align-items: center;
  gap:1vw;
}

.blocContentTitle{
  display: flex;
  flex-direction: column;
  gap:1vw;
}

.blocFormInputContact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:3%;
  margin: 0 auto;
  width: 50%;
}



input[type="text"] {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  background-color: #f9f9f9;
  font-size: 14px;
  font-family: Arial, sans-serif;
}

input[type="placeholder"] {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  background-color: #f9f9f9;
  font-size: 14px;
  font-family: Arial, sans-serif;
}

.blocInputMessage input[type="placeholder"] {
  height: 100px;
}

.buttonSendForm {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #11B622;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

.buttonSendForm:hover {
  background-color: #0fc722;
}

.buttonSendForm a:hover{
  color:white!important;
}

.contentProPage{
  margin-bottom: 8vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 2vh;
  align-items: center;
}


.bloc1Service{
  display: flex;
  height: 70vh;
  justify-content: space-around;
  text-align: center;
}

.blocContentQuiService{
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 2vw;
}

.blocContentQuiServiceElagage{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 2vw;
}

.bloc2Service{
  margin-top:10vw;
  margin-bottom: 10vw;
  display: flex;
  height: 70vh;
  justify-content: space-between;
}

.imgLongueService{
  width: 30%;
  box-shadow: 8px 7px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.contentBloc3Serv{
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  height: 20vh;
  margin-bottom: 10vw;
}

.blocTitleMap{
  display: flex;
  justify-content: center;
  gap:4%;
  align-items: center;
  width: 100%;
  padding-top: 5vw;
  margin-bottom: 5vw;
}

.blocMap{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10vw;
  gap:2vw;
}

.blocMapPrinc{
  display: flex;
  width: 100%;
  justify-content: center;
  height:50vh;

}

.mapComponentBloc{
  width: 45%;
  height: 100%;
  object-fit: contain;
}

.contentMapAcco{
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mapCompo{
  width: 100%;
  height: 100%;
}

.accordion-root {
  margin: 10px 0;
  border: none;
  box-shadow: none;
width: 20vw;
}

.accordion-summary {
  background-color: #f5f5f5;
  font-weight: bold;
  border: none;
}

.accordion-details {
  background-color: #ffffff;
  padding: 10px;
  border: none;
}

.MuiPaper-root{
  box-shadow: none!important;
  justify-content: start!important;
  align-items: start!important;
  display: flex!important;
  flex-direction: column!important;
  width: 100%;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.MuiButtonBase-root{
  width: 100%!important;
  justify-content: space-between!important;
  display: flex!important;
}

.MuiButtonBase-root:hover{
  cursor: default!important;
}

.MuiAccordionSummary-content{
  justify-content: center;
}

.pagination-arrow {
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
}

.pagination-arrow.active {
  transform: scale(0.9);
}

.blocPagination{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.css-15v22id-MuiAccordionDetails-root{
  padding:0!important;
}

.MuiCollapse-root{
  width: 18vw;
}

.blocFLechePagination{
  display: flex;
}

.MuiAccordionSummary-content p{
  font-weight: 800!important;
  font-family: "Comfortaa", sans-serif!important;
}

.MuiTypography-root p{
  font-family: "Comfortaa", sans-serif!important;
}

.blocFinContact{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:1vw;
}

.logoFin{
  width: 20%;
}


.gallery-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  min-height: 47vh;
}


.gallery-image {
  max-width: 30%;
  min-width: 20%;
  min-height:20vh ;
  max-height: 30vh;
  object-fit: cover;
  margin: 2%;
  transition: transform 200ms ease-in-out;
  border-radius: 5px;
}


.gallery-image:hover {
  transform: scale(1.05);
  cursor: pointer;
}


.blocRealisation{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.containerPageRealisation{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageContainerRealisation{
  padding: 0 12.5vw;
  width: 100vw;
  height:fit-content;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
}

.popup-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.popup-close-button {
  position: absolute;
  top: 5vw;
  right: 5vw;
  background: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 3%;
}

.croixPopup{
  width: 30px;
}

.secondTextReal{
  cursor: pointer;
  transition: transform 200ms ease-in-out;
}

.secondTextReal:hover{
  transform: scale(1.05);
}

.blocAcco{
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.05);
  }
  40% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.transitionButton {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px 10px;
  margin-top: 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: fit-content;
  transition: background-color 0.3s, transform 0.3s;
  animation: pulse 3s infinite;
}

.transitionButton:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.transitionButton svg {
  margin-right: 10px;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.blocCardAvantAp{
  display: flex;
  width: 100%;
  margin-top:2.5vw;
  margin-bottom:2.5vw;
  box-shadow: 8px 7px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: transparent;
}

.blocContentAvAp{
  width: 40%;
}

.blocImgAvant{
  width: 55%;
  display: flex;
  gap:4%;
  padding-left: 1%;
}

.imgAvantAp{
  width: 310px;
  height: 45vh;
  object-fit: cover;
  margin-top: 2vw;
  box-shadow: 3px 2px 1px 0px rgba(0, 0, 0, 0.25);
}

.absoluteImg{
  position: relative;
  top: -85%;
  left: 2%;
color: white;
  background-color: rgba(5, 5, 5, 0.55);
  width: fit-content;
  padding: 0 6px;
  border-radius: 8px;
}

.blocImageAvAp{
  height:100%;
}

.blocContentAvAp{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 2%;
  height:100%;
}

.ligneCard{
  height:1px;
  width: 20%;
  background-color: black;
}

.scroll-to-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 1000;

}

.scroll-to-top .block-arrow {

  display: flex;
  align-items: center;
  justify-content: center;



}

.block-arrow{
  transition: transform 0.1s ease-in-out;
  border-radius: 50%;
  background-color: rgb(17, 182, 34);
  transition: transform 0.1s ease-in-out;
  padding: 15%;
  cursor: pointer;
}

.block-arrow:hover{
  transform: scale(0.92);
}


 .arrow {
  width: 4vw;
   transform: rotate(90deg);
 }

.burger-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.line {
  width: 100%;
  height: 3px;
  background-color: black;
  transition: transform 0.3s ease, opacity 0.3s ease;
}



.burger-button:hover .line{
  background-color: #11B622;
}


.burger-button.open .line:nth-child(1) {
  transform: translateY(12px) rotate(45deg);
}

.burger-button.open .line:nth-child(2) {
  opacity: 0;
}

.burger-button.open .line:nth-child(3) {
  transform: translateY(-12px) rotate(-45deg);
}

.menu {
  position: fixed;
  top: 0;
  padding-top: 64px;
  background-color: white;
  width: 100vw;
  height: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 2;
}

.menu.open {
  transform: translateX(0);
}

.menu a {
  display: block;
  padding: 15px;
  text-decoration: none;
  color: black;
  border-bottom: 1px solid #eee;
}

.burger-button{
  display: none;
  position: absolute;
  right: 20px;
}

.blocTelNav:hover p{
  color:#11B622!important ;
}

.blocBurger{
  display: none;
  width: 100%;
  align-items: center;
  justify-content: center;
}

/*
//
//Responsive Tablette
//
*/

@media only screen and (max-width: 1120px) and (min-width: 768px) {

  .titleRealQueri{
    text-align: start!important;
  }

  .quiSommeQuerie{
    text-align: center;
  }

  .pageContainer{
    padding:0 100px ;
    display: flex;
    flex-direction: column;
    gap:4vh;
  }

  .img1Qui{
    height: 40vh;
    object-fit: cover;
  }

  .imgSigleServe{
    width: 20%;
  }

  .icoDrawReduc{
    width: 20%;
    height: auto!important;
  }

  body{
    padding-top: 14vh;
  }

  .burger-button{
    display: flex;
  }


  .navbar-links{
    display: none;
  }

  .navbar-icons{
    display: none;
  }

  .navbar{
    justify-content: end;
    padding-right: 5vw;
    height: 64px;

  }
  .imgNavLogoBurger{
    width: 60px;
  }

  .blocBurger{
    display: flex;
  }

  .bloc1Service{
    flex-direction: column;
    height: auto;
    align-items: center;
    gap:4vh;
  }
  .bloc2Service{
    flex-direction: column;
    height: auto;
    align-items: center;
    gap:4vh;
  }

  .blocBienvenue{
    flex-direction: column;
    height: auto;
    align-items: center;
    gap:4vh;
  }

  .blocQuiSomNous{
    flex-direction: column;
    height: auto;
    align-items: center;
    margin: 0;
    gap: 4vh;
  }

  .blocContentMiddle{
    flex-direction: column;
    height: auto;
    align-items: center;
    margin: 0;
    gap:2vh;
  }

  .blocListeReduc{
    flex-direction: column;
    height: auto;
    align-items: center;
    gap: 2vh;
    text-align: center;
  }

  .blocFormContact{
    padding-top:8vh ;
    margin-bottom:20vh;
  }

  .blocDirectionFooter{
    width:auto;
  }

  .textGreen{
    text-align: center;
  }

  .contentProPage{
    height: auto;
    text-align: center;
    gap:4vh;
  }

  .imgLanding1{
    width: 100%;
  }

  .blocContentBienvenue{
    width: 100%;
    gap:2vh;
    padding: 0;
  }

  .anirei{
    width: 100%!important;
    display: flex;
    justify-content: center;
  }

  .blocContentQui{
    width: 100%;
    padding:0 ;
  }

  .blocContentMidText{
    width: 100%;
    gap: 4vh;
    display: flex;
    flex-direction: column;
  }

  .blocImageReduc{
    width: 100%;
    justify-content: center;
    padding: 0;
  }

  .blocListeReducContent{
    width: 100%;
    text-align: start!important;
  }

  .blocContactContent{

  }

  .blocFormInputContact{
    width: 100%;
  }

  .img1QuiBis{
    width: 100%;
    display: none;
  }

  .img1QuiBisQuery{
    display: block;
    height: 40vh;
    object-fit: cover;
  }

  .img2Qui{
    display: none;
  }

  .imgLonguePage{
    margin: 0;
  }



  .blocContentQuiService{
    width: 100%;
  }

  .blocContentServ{
    width: 100%;
    padding: 0;
  }

  .imgLongueService{
    width: 90%;
    height: 40vh;
    object-fit: cover;
  }

  .contentBloc3Serv{
    height: auto;
    gap: 2vh;
  }

  .contentMapAcco{
    display: none;
  }

  .mapComponentBloc{
    height: auto;
    width: 90%;
  }

  .imgLanding1Queri{
    display: block;
    height: auto;
    border-radius: 5px;
    box-shadow: 8px 7px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .imgLanding1{
    display: none;
  }

  .blocReduc{
    gap: 2vh;
  }

  .contactQuerie{
  }

  .blocContactContent{
    gap: 2vh;
  }
  .blocFormInputContact{
    gap: 2vh;
  }

  .blocDirectionFooter{
    width: 80%;
  }

  .blocFooter{
    height: auto;
    padding: 2vh 0;
  }

  .logoFooter{
    width: 25%;
  }

  .buttonDarkMode{
    left: 20px;
  }
  .gallery-container{
    gap: 2vh;

  }

  .blocCardAvantAp{
    background-color: transparent;
    box-shadow: 8px 7px 4px 0px rgba(0, 0, 0, 0.25);
    flex-direction: column;
    padding: 10px;
  }

  .blocImgAvant{
    width: 100%;
    padding: 0;
    flex-direction: column;
  }

  .imgAvantAp{
    margin: 0;
    width: 100%;
    height: 100%;
  }
  .absoluteImg{
    top: -97%;
    background-color: rgba(5, 5, 5, 0.55);
    width: fit-content;
    padding:0 6px;
    border-radius: 8px;
    font-size: 93%;
    left: 1%;
  }

  .blocImageAvAp{
    height: 27vh;
  }

  .gallery-image{
    width: 100%;
    height: 40vh;
    min-height: unset;
    max-height: unset;
    min-width: unset;
    max-width: unset;
  }

  .blocContentAvAp{
    padding-top: 4vh;
    padding-left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:2vh;
  }

  .deleteComparaison:hover{
    transform: none!important;
  }
  .deleteComparaison{
    width: fit-content;
  }

  .deleteReal{
    top:20vw!important;
  }
}





/*
 //
 //Responsive mobile - grands écrans
 //
 */

@media only screen and (max-width: 767px) {
  .scroll-to-top{
    display: none;
  }

  .quiSommeQuerie{
    text-align: start;
  }

  .titleRealQueri{
    text-align: start!important;
  }

  .deleteComparaison{
    width: fit-content;
  }
  .deleteComparaison:hover{
    transform: none!important;
  }

  .gallery-container{
    gap: 2vh;

  }

  .blocCardAvantAp{
    background-color: transparent;
    box-shadow: 8px 7px 4px 0px rgba(0, 0, 0, 0.25);
    flex-direction: column;
    padding: 10px;
  }

  .blocImgAvant{
   width: 100%;
    padding: 0;
    flex-direction: column;
  }

  .imgAvantAp{
   margin: 0;
    width: 100%;
    height: 100%;
  }
  .absoluteImg{
    top: -97%;
    background-color: rgba(5, 5, 5, 0.55);
    width: fit-content;
    padding:0 6px;
    border-radius: 8px;
    font-size: 93%;
    left: 1%;
  }

  .blocContentAvAp{
    padding-top: 4vh;
    padding-left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:2vh;
  }

  .blocImageAvAp{
    height: 20vh;
  }

  .gallery-image{
    width: 100%;
    height: 40vh;
    min-height: unset;
    max-height: unset;
    min-width: unset;
    max-width: unset;
  }

  .arrow{
    width: 40px;
  }

  .pageContainer{
    padding:0 20px ;
    display: flex;
    flex-direction: column;
    gap:4vh;
  }

  .imgSigleServe{
    width: 20%;
  }

  .icoDrawReduc{
    width: 20%;
  }

  .img1Qui{
    height: 40vh;
    object-fit: cover;
  }

  body{
    padding-top: 14vh;
  }

  .burger-button{
    display: flex;
  }


  .navbar-links{
    display: none;
  }

  .navbar-icons{
    display: none;
  }

  .navbar{
    justify-content: end;
    padding-right: 5vw;
    height: 64px;
  }
  .imgNavLogoBurger{
    width: 60px;
  }

  .blocBurger{
    display: flex;
  }

  .bloc1Service{
    flex-direction: column;
    height: auto;
    align-items: center;
    gap:4vh;
  }
  .bloc2Service{
    flex-direction: column;
    height: auto;
    align-items: center;
    gap:4vh;
  }

  .blocBienvenue{
    flex-direction: column;
    height: auto;
    align-items: center;
    gap:4vh;
  }

  .blocQuiSomNous{
    flex-direction: column;
    height: auto;
    align-items: center;
    margin: 0;
    gap: 4vh;
  }

  .blocContentMiddle{
    flex-direction: column;
    height: auto;
    align-items: center;
    margin: 0;
    gap:2vh;
  }

  .blocListeReduc{
    flex-direction: column;
    height: auto;
    align-items: center;
    gap: 2vh;
    text-align: center;
  }

  .blocFormContact{
    flex-direction: column;
    height: auto;
    align-items: center;
    padding: 0;
    gap:4vh;
  }

  .blocDirectionFooter{
    width:auto;
  }

  .textGreen{
    text-align: center;
  }

  .contentProPage{
    height: auto;
    text-align: center;
    gap:4vh;
  }

  .imgLanding1{
    width: 100%;
  }

  .blocContentBienvenue{
    width: 100%;
    gap:2vh;
    padding: 0;
  }

  .anirei{
    width: 100%!important;
    display: flex;
    justify-content: center;
  }

  .blocContentQui{
    width: 100%;
    padding:0 ;
  }

  .blocContentMidText{
    width: 100%;
    gap: 4vh;
    display: flex;
    flex-direction: column;
  }

  .blocImageReduc{
    width: 100%;
    justify-content: center;
    padding: 0;
  }

  .blocListeReducContent{
    width: 100%;
    text-align: start!important;
  }

  .blocContactContent{
    width: 100%;
    margin: 0;
    text-align: center;
  }

  .blocFormInputContact{
    width: 100%;
  }

  .img1QuiBis{
    width: 100%;
    display: none;
  }

  .img1QuiBisQuery{
    display: block;
    height: 40vh;
    object-fit: cover;
  }

  .img2Qui{
    display: none;
  }

  .imgLonguePage{
   margin: 0;
  }



  .blocContentQuiService{
    width: 100%;
  }

  .blocContentServ{
    width: 100%;
    padding: 0;
  }

  .imgLongueService{
    width: 100%;
    height: 40vh;
    object-fit: cover;
  }

  .contentBloc3Serv{
    height: auto;
    gap: 2vh;
  }

  .contentMapAcco{
    display: none;
  }

  .mapComponentBloc{
    height: auto;
    width: 90%;
  }

  .imgLanding1Queri{
    display: block;
    height: auto;
    border-radius: 5px;
    box-shadow: 8px 7px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .imgLanding1{
    display: none;
  }

  .blocReduc{
    gap: 2vh;
  }

  .contactQuerie{
    display: none;
  }

  .blocContactContent{
    gap: 4vh;
  }
  .blocFormInputContact{
    gap: 2vh;
  }

  .blocDirectionFooter{
    flex-direction: column;


  }

  .blocFooter{
    height: auto;
    padding: 2vh 0;
  }

  .logoFooter{
    width: 25%;
  }

  .buttonDarkMode{
    left: 20px;
  }

  .deleteReal{
    top:20vw!important;
  }
}




.buttonDarkMode{
  background-color: transparent!important;
  cursor: pointer;
  position: absolute;
  left: 4%;
}








/*
//
//Toggle dark mode
//
 */

/* switch settings 👇 */

.ui-switch {
  /* switch */
  --switch-bg: rgb(135, 150, 165);
  --switch-width: 48px;
  --switch-height: 20px;
  /* circle */
  --circle-diameter: 32px;
  --circle-bg: #11B622;
  --circle-inset: calc((var(--circle-diameter) - var(--switch-height)) / 2);
}

.ui-switch input {
  display: none;
}

.slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: var(--switch-width);
  height: var(--switch-height);
  background: var(--switch-bg);
  border-radius: 999px;
  position: relative;
  cursor: pointer;
}

.slider .circle {
  top: calc(var(--circle-inset) * -1);
  left: 0;
  width: var(--circle-diameter);
  height: var(--circle-diameter);
  position: absolute;
  background: var(--circle-bg);
  border-radius: inherit;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHdpZHRoPSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICAgIDxwYXRoIGZpbGw9IiNmZmYiCiAgICAgICAgZD0iTTkuMzA1IDEuNjY3VjMuNzVoMS4zODlWMS42NjdoLTEuMzl6bS00LjcwNyAxLjk1bC0uOTgyLjk4Mkw1LjA5IDYuMDcybC45ODItLjk4Mi0xLjQ3My0xLjQ3M3ptMTAuODAyIDBMMTMuOTI3IDUuMDlsLjk4Mi45ODIgMS40NzMtMS40NzMtLjk4Mi0uOTgyek0xMCA1LjEzOWE0Ljg3MiA0Ljg3MiAwIDAwLTQuODYyIDQuODZBNC44NzIgNC44NzIgMCAwMDEwIDE0Ljg2MiA0Ljg3MiA0Ljg3MiAwIDAwMTQuODYgMTAgNC44NzIgNC44NzIgMCAwMDEwIDUuMTM5em0wIDEuMzg5QTMuNDYyIDMuNDYyIDAgMDExMy40NzEgMTBhMy40NjIgMy40NjIgMCAwMS0zLjQ3MyAzLjQ3MkEzLjQ2MiAzLjQ2MiAwIDAxNi41MjcgMTAgMy40NjIgMy40NjIgMCAwMTEwIDYuNTI4ek0xLjY2NSA5LjMwNXYxLjM5aDIuMDgzdi0xLjM5SDEuNjY2em0xNC41ODMgMHYxLjM5aDIuMDg0di0xLjM5aC0yLjA4NHpNNS4wOSAxMy45MjhMMy42MTYgMTUuNGwuOTgyLjk4MiAxLjQ3My0xLjQ3My0uOTgyLS45ODJ6bTkuODIgMGwtLjk4Mi45ODIgMS40NzMgMS40NzMuOTgyLS45ODItMS40NzMtMS40NzN6TTkuMzA1IDE2LjI1djIuMDgzaDEuMzg5VjE2LjI1aC0xLjM5eiIgLz4KPC9zdmc+");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -o-transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
;
}

.slider .circle::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  border-radius: inherit;
  -webkit-transition: all 500ms;
  -o-transition: all 500ms;
  transition: all 500ms;
  opacity: 0;
}

/* actions */

.ui-switch input:checked+.slider .circle {
  left: calc(100% - var(--circle-diameter));
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjAiIHdpZHRoPSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICAgIDxwYXRoIGZpbGw9IiNmZmYiCiAgICAgICAgZD0iTTQuMiAyLjVsLS43IDEuOC0xLjguNyAxLjguNy43IDEuOC42LTEuOEw2LjcgNWwtMS45LS43LS42LTEuOHptMTUgOC4zYTYuNyA2LjcgMCAxMS02LjYtNi42IDUuOCA1LjggMCAwMDYuNiA2LjZ6IiAvPgo8L3N2Zz4=");
}

.ui-switch input:active+.slider .circle::before {
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
  opacity: 1;
  width: 0;
  height: 0;
}

.accordion-typography{
  cursor: default;
}

.container-mention{
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 12.5vw;
  padding-bottom: 7vw;
  width: 100vw;
  height:fit-content;
}
.title-mention{
  font-style: italic;
}
.para{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.h1mention{
  font-size: 1.5rem;
}
.eror-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3vw;
}
.img-tondeuse{
  height: 700px;
  width: 700px;
  object-fit: contain;
  box-shadow: 8px 0px 4px 0px rgba(0, 0, 0, 0.25);
}


.deleteComparaison{
  position: relative;
  top:10px;
  right:10px;
  height: fit-content;
}

.deleteReal{
  z-index: 1000;
  background-color: red;
  border-radius: 5px;
  color: white;
  padding: 10px 15px;
  position: absolute;
  top: 10vw;
  right: 5vw;
}

.error {
  color: red;
  font-size: 12px;
}

.inputLarge{
  color: black;
}

.inputMessage{
  padding-bottom: 5rem!important;
}

#notfound {

}

#notfound .notfound {

  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 520px;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left:50%;
}

.notfound .notfound-bg {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
}

.notfound .notfound-bg > div {
  width: 100%;
  background: rgb(222, 221, 221);
  border-radius: 90px;
  height: 125px;
}

.notfound .notfound-bg > div:nth-child(1) {
  -webkit-box-shadow: 5px 5px 0px 0px #7a7a7a;
  box-shadow: 5px 5px 0px 0px #7a7a7a;
}

.notfound .notfound-bg > div:nth-child(2) {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-box-shadow: 5px 5px 0px 0px #7a7a7a;
  box-shadow: 5px 5px 0px 0px #7a7a7a;
  position: relative;
  z-index: 10;
}

.notfound .notfound-bg > div:nth-child(3) {
  -webkit-box-shadow: 5px 5px 0px 0px #7a7a7a;
  box-shadow: 5px 5px 0px 0px #7a7a7a;
  position: relative;
  z-index: 90;
}

.notfound h1 {
  font-family: "Comfortaa", sans-serif;
  font-size: 86px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 8px;
  color: #151515;
}

.notfound h2 {
  font-family: "Comfortaa", sans-serif;
  font-size: 26px;
  margin: 0;
  font-weight: 700;
  color: #151515;
}

.notfound a {
  font-family: "Comfortaa", sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #11B622;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 5px;
  color: #fff;
  font-weight: 700;
  margin-top: 20px;
}

.notfound-social {
  margin-top: 20px;
}

.notfound-social>a {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  width: 40px;
  font-size: 14px;
  color: #fff;
  background-color: #dedede;
  margin: 3px;
  padding: 0px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}


@media only screen and (max-width: 767px) {
  .notfound .notfound-bg {
    width: 287px;
    margin: auto;
  }

  .notfound .notfound-bg > div {
    height: 85px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound h1 {
    font-size: 68px;
  }

  .notfound h2 {
    font-size: 18px;
  }
}

.toto:hover{
  color:white!important;
  transform: scale(1.1);
}


.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
